<template>
	<div>
		<div class="flex justify-start">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="项目名称">
					<el-input v-model="searchForm.name" placeholder="项目名称"></el-input>
				</el-form-item>
				<el-form-item label="项目分类">
					<el-select v-model="classifyIndex" placeholder="全部" clearable @change="search()">
						<el-option v-for="(item,index) in classify" :key="index" :label="item.name" :value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="searchForm.status" placeholder="全部" clearable @change="search()">
						<el-option label="上架" :value="1"></el-option>
						<el-option label="下架" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="serve_name" label="项目名称" width="280">
				<template slot-scope="scope">
					<div class="flex align-center">
						<el-image v-if="scope.row.cover" class="cover" :src="scope.row.cover" fit="cover"></el-image>
						{{scope.row.serve_name}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="classify_name" label="项目分类" width="180">
			</el-table-column>
			<el-table-column prop="price" label="项目价格" width="180">
				<template slot-scope="scope">
					<div>价格：{{scope.row.price}}</div>
					<!-- <div class="old-price">原价：{{scope.row.current_price}}</div> -->
				</template>
			</el-table-column>
			<el-table-column prop="service_type" label="支持服务方式" width="180">
				<template slot-scope="scope">
					<div v-if="scope.row.service_type == 1">到店</div>
					<div v-if="scope.row.service_type == 2">上门</div>
					<div v-if="scope.row.service_type == 3">到店、上门</div>
				</template>
			</el-table-column>
			<el-table-column prop="service_fee" label="上门服务费" width="180">
			</el-table-column>
			<el-table-column prop="sales_commission" label="销售提成" width="180">
				<template slot-scope="scope">
					<div>
						{{scope.row.sales_commission|toFixed2}}
						<!-- <el-button icon="el-icon-edit" circle size="mini" @click="showEdit(scope.row)"></el-button> -->
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					<div v-if="scope.row.status" class="text-green">正常</div>
					<div v-else class="text-gray">停用</div>
					<!-- <el-switch :value="scope.row.status" :active-value="1" :inactive-value="0" @change="publish(scope.row)"></el-switch> -->
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button @click="showEdit(scope.row)" size="mini">编辑</el-button>
					<el-button @click="publish(scope.row)" size="mini" v-if="scope.row.status" type="danger">停用</el-button>
					<el-button @click="publish(scope.row)" size="mini" v-else type="success">启用</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
		<el-dialog title="编辑" :visible.sync="editDialog.visible" append-to-body :close-on-click-modal="false">
			<el-form :model="editDialog.form" @submit.native.prevent label-width="100px" label-position="left">
				<el-form-item label="名称">
					{{editDialog.form.serve_name}}
				</el-form-item>
				<el-form-item label="售价">
					{{editDialog.form.price}}
				</el-form-item>
				<el-form-item label="销售提成">
					<el-input-number v-model="editDialog.form.sales_commission" :min="0" label="销售提成" :precision="2"></el-input-number>
				</el-form-item>
				<el-form-item label="是否支持上门">
					<el-switch v-model="editDialog.form.service_type" :active-value="3" :inactive-value="1"></el-switch>
				</el-form-item>
				<el-form-item label="上门服务费">
					<el-input-number v-model="editDialog.form.service_fee" :min="0" label="上门服务费" :precision="2"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="editDialog.visible = false;search()">取 消</el-button>
				<el-button type="primary" @click="editSubmit()" :loading="editDialog.loading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				classify: [], // 分类选项
				classifyIndex: '', // 选择的分类index
				pageSizes: [10, 20, 30, 50],
				searchForm: {
					name: undefined, // 否 string 产品名称
					classify: undefined, // 否 array 分类ID
					status: undefined, // 否 int 状态
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
					total: 0,
				},
				editDialog: {
					loading: false,
					visible: false,
					form: {
						id:'',
						service_fee: '',
						service_type: '',
						sales_commission:''
					}
				}
			};
		},
		mounted() {
			// 获取项目分类
			this.$api.classify.getBrandClassify(this.$api.classify.SERVICE).then(res => {
				this.classify = res.data
			})
			// 查询数据
			this.search()
		},
		methods: {
			editSubmit() {
				// 校验价格
				if(this.editDialog.form.sales_commission > Number(this.editDialog.form.price)){
					this.$message.warning('销售提成不能大于商品价格')
					return;
				}
				// 提交数据
				this.editDialog.loading = true;
				this.$api.project.saveBrandInfo({
					id: this.editDialog.form.id,
					sales_commission: this.editDialog.form.sales_commission,
					service_fee: this.editDialog.form.service_fee,
					service_type: this.editDialog.form.service_type
				}).then(res => {
					this.editDialog.loading = false;
					if (res.code == 200) {
						this.$message.success('保存成功')
						this.editDialog.visible = false;
						this.search()
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			showEdit(row) {
				this.editDialog.form = row;
				this.editDialog.visible = true;
			},
			search() {
				this.loading = true;
				// 将对象空字符串转为undefined
				let searchForm = this.$util.emptyToUndefined(this.searchForm);
				if (this.classifyIndex !== '') {
					searchForm.classify = this.classify[this.classifyIndex].children.map(item => {
						return item.id
					})
				} else {
					searchForm.classify = []
				}
				this.$api.project.getBrandProjects(searchForm).then(res => {
					this.searchForm.total = res.data.count;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			toEdit(item) {
				if (item) {
					this.$router.push(`/project/edit/${item.id}`)
				} else {
					this.$router.push(`/project/edit`)
				}
			},
			del(item) {
				const loading = this.$loading()
				this.$api.project.del({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(`操作成功`);
						this.search()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			publish(item) {
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.project.setBrandStatus({
					id: item.id
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
